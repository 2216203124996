import { createRef } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import DemystLogo from "../../assets/svg/DemystLogo.svg";
import { footerMenu } from "../../datasets/footerMenu";
import { PauseOutsideViewport } from "../../views/components/logic/PauseOutsideViewport";
import { FiLinkedin, FiTwitter } from "react-icons/fi";
import clsx from "clsx";
import { LinkButton } from "../../views/components/LinkButton";

export default function Footer({ showNav = true }) {
  const router = useRouter();
  const current = router.asPath;
  const ref = createRef();

  const isLanding = !showNav;

  const animationsToPause = {
    childrenOf: ["#footer_data_lanes"],
    ids: [],
  };

  const scroll = link => {
    if (current === "/platform" || link.indexOf("#") !== -1) {
      let hash = link.slice(link.indexOf("#") + 1);
      let element = document.getElementById(hash);
      if (element !== null) {
        let rect = element.getBoundingClientRect();
        let scrollTop = window.pageYOffset;
        let result = rect.top + scrollTop - 130;
        window.scrollTo({
          top: result,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className={clsx("layout-footer", isLanding && "layout-footer-landing")}>
      <section className="section-layout">
        <PauseOutsideViewport ref={ref} animationsToPause={animationsToPause} />
        <section className="layout-footer-main">
          <div className="layout-footer-left">
            <Link href="/" className="layout-header-logo" title="Return home">
              <img
                src={DemystLogo.src}
                className="layout-footer-logo"
                alt="Demyst"
                width="162"
                height="40"
                loading="lazy"
              />
            </Link>
            <ul className="layout-footer-social">
              {footerMenu.social?.map(link => (
                <li key={link.type} className="layout-footer-social-item">
                  <a
                    href={link.url}
                    rel="noreferrer"
                    target="_blank"
                    className="layout-footer-social-link"
                    title={link.type}
                  >
                    {link.type == "Twitter" && <FiTwitter alt="Twitter icon" />}
                    {link.type == "LinkedIn" && <FiLinkedin alt="Linkedin icon" />}
                  </a>
                </li>
              ))}
            </ul>
            {isLanding && (
              <nav className="layout-footer-landingNav">
                {footerMenu.columns
                  .filter(c => c.header === "Legal")
                  .map(c =>
                    c.items.map((link, index) => (
                      <>
                        <Link
                          href={link.url}
                          isExternal={link.isExternal}
                          className="layout-footer-landingNav-link"
                          onClick={() => scroll(link.url)}
                        >
                          {link.name}
                        </Link>
                        {index < c.items.length - 1 && (
                          <span className="layout-footer-landingNav-divider">&middot;</span>
                        )}
                      </>
                    )),
                  )}
              </nav>
            )}
            <p className="layout-footer-copy">
              <span>{footerMenu.copy}</span>
              <span>All rights reserved.</span>
            </p>
            {isLanding && (
              <LinkButton className="layout-footer-toTop" onClick={() => scrollToTop()}>
                Back To Top
              </LinkButton>
            )}
          </div>
          {!isLanding && (
            <nav className="layout-footer-nav">
              {footerMenu.columns.map((item, index) => (
                <div
                  id={`layout-footer-column-${index}`}
                  aria-label={`layout-footer-column-${index}`}
                  key={item.header}
                  className="layout-footer-column"
                >
                  <div className="layout-footer-header">{item.header}</div>
                  <ul>
                    {item.items.map(link => (
                      <li key={link.name} className="layout-footer-list-item">
                        {link.img ? <img src={link.img.src} alt="" /> : null}
                        <Link
                          href={link.url}
                          isExternal={link.isExternal}
                          className="layout-footer-link"
                          onClick={() => scroll(link.url)}
                        >
                          {link.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </nav>
          )}
        </section>
      </section>
      <noscript>
        <img
          src="https://ws.zoominfo.com/pixel/62b92deed06391008ee7e092"
          width="1"
          height="1"
          style={{ display: "none" }}
          alt="websights"
        />
      </noscript>
    </footer>
  );
}

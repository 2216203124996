import { forwardRef, useEffect } from "react";

export const PauseOutsideViewport = forwardRef((props, ref) => {
  const toggleAnimation = state => {
    const animationsToPause = props.animationsToPause;
    let list = [];
    if (animationsToPause.ids.length !== 0) {
      list = [...list, ...document.querySelectorAll(animationsToPause.ids)];
    }
    if (animationsToPause.childrenOf.length !== 0) {
      animationsToPause.childrenOf.forEach(parent => {
        if (document.querySelector(parent) !== null)
          list = [...list, ...document.querySelector(parent).childNodes];
      });
    }
    list.forEach(item => {
      if (state === "pause") {
        item.classList.add("pause");
      } else if (state === "run") {
        item.classList.remove("pause");
      }
    });
  };

  useEffect(() => {
    if ("IntersectionObserver" in window) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting === false) {
            toggleAnimation("pause");
          } else {
            toggleAnimation("run");
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.1,
        },
      );
      if (ref.current) {
        observer.observe(ref.current);
      }
    }
  }, [ref]);

  return null;
});

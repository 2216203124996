import { linksConstants } from "../../constants/linksConstants";

export const footerMenu = {
  copy: `© ${new Date().getFullYear()} DemystData, Ltd.`,
  social: [{ type: "LinkedIn", url: "https://www.linkedin.com/company/demystdata" }],
  columns: [
    {
      header: "Platform",
      items: [
        {
          name: "Overview",
          url: linksConstants.PLATFORM,
          isExternal: false,
        },
        {
          name: "Documentation",
          url: "https://docs.demyst.com/",
          isExternal: true,
        },
        {
          name: "Request Demo",
          url: linksConstants.REQUEST_DEMO,
          isExternal: false,
        },
      ],
    },
    {
      header: "Resources",
      items: [
        {
          name: "Whitepapers and Success Stories",
          url: linksConstants.RESOURCES,
          isExternal: false,
        },
        {
          name: "Powering Case Studies",
          url: linksConstants.POWERING,
          isExternal: false,
        },
        //        {
        //          name: "Pricing",
        //          url: linksConstants.PRICING,
        //          isExternal: false,
        //        }
      ],
    },
    {
      header: "Company",
      items: [
        { name: "About us", url: linksConstants.ABOUT, isExternal: false },
        { name: "Contact us", url: linksConstants.CONTACT, isExternal: false },
        { name: "Careers", url: linksConstants.CAREERS, isExternal: false },
      ],
    },
    {
      header: "Legal",
      items: [
        {
          name: "Privacy Policy",
          shortName: "Privacy",
          url: linksConstants.PRIVACY,
          isExternal: false,
        },
        {
          name: "Accessibility Policy",
          shortName: "Accessibility",
          url: linksConstants.ACCESSIBILITY,
          isExternal: false,
        },
        {
          name: "Terms and Conditions",
          shortName: "Terms",
          url: linksConstants.TERMS,
          isExternal: false,
        },
        {
          name: "Sub-processor Policy",
          shortName: "Sub-processor",
          url: linksConstants.SUBPROCESSOR_POLICY,
          isExternal: false,
        },
        {
          name: "Cookie Policy",
          shortName: "Cookies",
          url: linksConstants.COOKIE_POLICY,
          isExternal: false,
        },
        {
          name: "Manage Cookies",
          hideShort: true,
          url: linksConstants.MANAGE_COOKIE,
          isExternal: false,
        },
        {
          name: "Demyst Global SLAs",
          shortName: "SLAs",
          url: "/Demyst - Global SLAs - v2.6.pdf",
          isExternal: true,
        },
        {
          name: "Personal Information Request (CA)",
          hideShort: true,
          url: linksConstants.CCPA,
          isExternal: false,
        },
        {
          name: "Data Subject Action Request",
          hideShort: true,
          url: linksConstants.DSAR,
          isExternal: false,
        },
      ],
    },
  ],
};
